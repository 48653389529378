import { Environment } from '@integration-frontends/apps/common/utils/environment-types';
// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: true,
  segmentWriteKey: '3D2vwp7cRNdeQQAR0agYzuEH3oAi3XWA',
  bfBaseUrl: 'https://bf-stage.com/api',
  bfWebsiteBaseUrl: 'https://bf-stage.com',
  bfStaticAssetsBaseUrl: 'https://static-fast.bf-stage.com',
  hostUrl: 'https://integration-panel-ui-stage.brandfolder-svc.com',
  oauthBaseUrl: 'https://oauth2.stage.integration.bf-squads.com',
  oauthClientId: 'stage-demo-app',
  environment: Environment.Stage,
};
